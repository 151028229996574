<template>
  <BaseTemplate>
    <template slot="header-content">
      <div class="col-12 description-section">
        <div class="title">Text Classification (NLP)</div>
        <div class="description">
          Our NLP can be utilized to classify text, especially in Thai language.
          For this tool, it can automatically recognize and analyze part of
          speech (noun and verb) of words in Thai sentences. It also can
          classify Thai words by their entities such as person's names, places,
          organizations, etc.
        </div>
      </div>
    </template>
    <template slot="input-content">
      <NLPResultLeft
        v-if="result.text !== ''"
        class="mb-3"
      >
        <template slot="result-value">
          <span class="result-text" v-html="resultText"></span>
        </template>
      </NLPResultLeft>
      <NLPInput 
        class="mb-3"
        v-model="inputValue"
        v-if="result.text === ''" 
      ></NLPInput>
      <ButtonComponent 
        class="demo-bg-primary w-100"
        text="Clear All" 
        v-if="result.text !== ''"
        @click.native="clickBack()"/>
      <ButtonComponent 
        class="demo-bg-danger w-100"
        :class="{'disabled': inputValue.text===''}"
        icon="east" 
        text="Run Extraction" 
        v-if="result.text === ''"
        @click.native="clickSubmit()"/>
      <!-- <NLPSubmit></NLPSubmit> -->
    </template>
    <template slot="output-content">
      <NLPResult>
        <template slot="result-value">
          <div class="row" v-if="result.filters.length > 0">
            <div class="col-12 mb-3">
              <div class="card">
                <div class="card-header d-flex">
                  <span class="flex-grow-1">Filters</span>
                  <span class="badge bg-primary mx-1" role="button" @click="selectFilterAll()">เลือกทั้งหมด</span>
                  <span class="badge bg-danger mx-1" role="button" @click="deSelectFilterAll()">ล้างทั้งหมด</span>
                </div>
                <div class="card-body">
                  <span class="filter-type fs-5 mx-1 mb-3" :class="'badge ' + checkColor(val.key)" v-for="(val,index) of result.filters" :key="index">
                    <span class="form-check">
                      <input class="form-check-input" type="checkbox" value="" :id="'checkboxFilter-' + index" v-model="val.isSelect">
                      <label class="form-check-label pt-1" :for="'checkboxFilter-' + index">
                        {{ val.key }}
                      </label>
                    </span>
                  </span>
                </div>
              </div>
            </div>              
          </div>
          <div class="row" v-if="result.groups.length > 0">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  Roles
                </div>
                <div class="card-body">
                  <div class="row" v-for="(groupValue, groupIndex) of result.groups" :key="groupIndex">
                    <div class="col-12 col-sm-2 mb-3">
                      {{ groupValue.key }}
                    </div>
                    <div class="col-12 col-sm-10">
                      <span class="filter-type fs-5 mx-1 mb-3" :class="'badge filter-type-' + groupValue.key" v-for="(val,index) of groupValue.values" :key="index">
                        {{ val }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>              
          </div>
        </template>
      </NLPResult>
    </template>
  </BaseTemplate>
</template>

<script>
import BaseTemplate from '@/templates/BaseTemplate.vue';
import NLPInput from '@/components/input/NLPInput.vue';
import NLPResultLeft from '@/components/result/NLPResultLeft.vue';
import ButtonComponent from '@/components/button/ButtonComponent.vue';
import NLPResult from '@/components/result/NLPResult.vue';
import axios from 'axios'
import { _data_ner, _data_summary } from "@/store/_data_ner.ts";

export default {
  components: { BaseTemplate, NLPInput, ButtonComponent, NLPResult, NLPResultLeft
  },
  name: 'NlpHome',
  data() {
    return {
      inputValue: {
        text: _data_ner.input_text,
        type: "ner"
      },   
      typeOptions: [
        { name: 'คำเฉพาะ', value: 'ner', keyUsed: 'entity' },
        { name: 'หลักไวยากรณ์', value: 'summary', keyUsed: 'pos', keyGroup: "role" },
      ],   
      result: {
        text: "",
        tokens: [],
        type: "",
        filters: [],
        colors: [],
        groups: [],
      }
    }
  },
  watch: {
    inputValue: function(newValue){
      this.demodata(newValue.type)
    }
  },
  methods: {
    demodata(type){
      if(this.inputValue.type===type){
        this.inputValue.text = _data_ner.input_text
      } else {
        this.inputValue.text = _data_summary.input_text
      }
    },
    selectFilterAll() {
      this.result.filters.map(x=>x.isSelect=true)
    },
    deSelectFilterAll() {
      this.result.filters.map(x=>x.isSelect=false)
    },
    clickBack() {
      this.loadingState = false;
      this.inputValue = {
        text: "",
        type: "ner"
      }
      this.result = {
        text: "",
        tokens: [],
        type: "",
        filters: [],
        colors: [],
        groups: [],
      }
    },
    checkColor(key){
      let index = this.result.colors.indexOf(key)
      return index === -1 || index>15 ? "filter-type-default" : "filter-type-" + index
    },
    clickSubmit(){

      const self = this
      this.loadingState = true; 
      if(self.inputValue.type==='ner'){
        self.result.text = _data_ner.input_text ?? ""
        self.result.tokens = _data_ner.tokens ?? []
        self.result.type = _data_ner.type ?? ""  
      } else {
        self.result.text = _data_summary.input_text ?? ""
        self.result.tokens = _data_summary.tokens ?? []
        self.result.type = _data_summary.type ?? ""  
      }

      // TODO: Make Axios
      let config = {
        method: 'post',
        url: 'https://ourworks-api-nlp.loolootest.com/' + this.inputValue.type,
        data : {
          text: this.inputValue.text
        }
      };
      axios(config)
        .then(function(res){
          self.result.text = res.data.input_text ?? ""
          self.result.tokens = res.data.tokens ?? []
          self.result.type = res.data.type ?? ""
          self.makeFilterAndGroup()
        })
        .catch(function(){
          console.log('FAILURE!!');
        });
    },
    makeFilterAndGroup(){
      const self = this
      let type = self.typeOptions.find((x) => x.value === self.result.type)
      // let filterToken = 
      self.result.tokens
        .filter(x=>(x[type.keyUsed] !== 'O'))
        .map(x=>{
          // color and filter
          if(x[type.keyUsed] && self.result.colors.indexOf(x[type.keyUsed]) === -1){
            self.result.colors.push(x[type.keyUsed])
            self.result.filters.push({
              key: x[type.keyUsed],
              isSelect: true
            })
          }

          // group
          if( type.keyGroup && type.keyGroup !== ""){
            if(x[type.keyGroup]){
              let keyIndex = self.result.groups.findIndex(y=>y.key===x[type.keyGroup]);
              if(keyIndex === -1){
                keyIndex = self.result.groups.length
                self.result.groups.push({ key: x[type.keyGroup], values:[]})
              }
              if(self.result.groups[keyIndex].values.indexOf(x.token) === -1){
                self.result.groups[keyIndex].values.push(x.token)
              }
            }
            
          }
          return x
        })

      self.result.colors = [...self.result.colors , ...self.result.groups.map(x=>x.key)]
    },
  },
  computed: {
    resultText: function() {
      const self = this
      let type = self.typeOptions.find((x) => x.value === self.result.type)
      let runIdx = 0;
      let filterToken = self.result.tokens
        .filter(x=>(x[type.keyUsed] !== 'O'))
        .reduce((str, arr) => {

        // replace with key to string
        let index = self.result.text.substr(runIdx).indexOf(arr.token)
        if (index !== -1) {
          // if not in badge
          str += self.result.text.substr(runIdx, index)
          runIdx += index
          // TODO: Add badge here
          if( type.keyGroup && arr[type.keyGroup]){
            str += '<span class="mx-1 badge ' + self.checkColor(arr[type.keyGroup]) + '">'
            + '<span class="d-inline-flex">'
            + '<span class="align-self-center">'
            + self.result.text.substr(runIdx, arr.token.length)
            + '</span>'
            + '<span class="align-self-center badge bg-white text-dark ms-1">'
            + arr[type.keyGroup]
            + '</span>'
            + '</span>'
            + '</span>'
          } else if(self.result.filters?.find(x=>x.key === arr[type.keyUsed])?.isSelect ?? false) {
            str += '<span class="mx-1 badge ' + self.checkColor(arr[type.keyUsed]) + '">'
            + '<span class="d-inline-flex">'
            + '<span class="align-self-center">'
            + self.result.text.substr(runIdx, arr.token.length)
            + '</span>'
            + '<span class="align-self-center badge bg-white text-dark ms-1">'
            + arr[type.keyUsed]
            + '</span>'
            + '</span>'
            + '</span>'
          } else {
            str += self.result.text.substr(runIdx, arr.token.length)
          }

          
        //   /////////
          runIdx += arr.token.length
          
        }
        return str
      }, '')

      if(runIdx < self.result.text.length){
        filterToken += self.result.text.substr(runIdx)
      }

      return filterToken
    }
  },
}
</script>

<style>
.filter-type-0 {
  background: #ffb74d !important;
}

.filter-type-1 {
  background: #f48fb1 !important;
}

.filter-type-2 {
  background: #81c784 !important;
}

.filter-type-3 {
  background: #64b5f6 !important;
}

.filter-type-4 {
  background: #ffb74d !important;
}

.filter-type-5 {
  background: #f48fb1 !important;
}

.filter-type-6 {
  background: #81c784 !important;
}

.filter-type-7 {
  background: #64b5f6 !important;
}

.filter-type-8 {
  background: #ffb74d !important;
}

.filter-type-9 {
  background: #f48fb1 !important;
}

.filter-type-10 {
  background: #81c784 !important;
}

.filter-type-11 {
  background: #64b5f6 !important;
}

.filter-type-12 {
  background: #ffb74d !important;
}

.filter-type-13 {
  background: #f48fb1 !important;
}

.filter-type-14 {
  background: #81c784 !important;
}

.filter-type-15 {
  background: #64b5f6 !important;
}

.filter-type {
  background: #64b5f6;
}
.filter-type-B-PERSON {
  background: #81c784 !important;
}

.filter-type-I-PERSON {
  background: #64b5f6 !important;
}

.filter-type-B-TIME {
  background: #ef9a9a !important;
}

.filter-type-I-TIME {
  background: #ffb74d !important;
}

span.filter-key {
  background: #fdfefe !important;
  border-radius: 4px;
  padding: 0 10px;
  margin-left: 10px;
  font-size: 10px;
  height: 20px;
  line-height: 2;
}

span.result-text {
  line-height: 30px;
}

.result-box {
  min-height: 500px;
  height: 500px;
  overflow-y: scroll;
}

span.filter-type-victim {
  background: #ffb74d !important;
}

span.filter-type-subject {
  background: #f48fb1 !important;
}

span.filter-type-charge {
  background: #81c784 !important;
}
</style>
